/**
 * @generated SignedSource<<2ce3257a1b715d5cf85295b891178a95>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type MemberRole = "ADMIN" | "MEMBER" | "OWNER" | "%future added value";
export type invitesPageQuery$variables = {
  inviteId: string;
};
export type invitesPageQuery$data = {
  readonly invite: {
    readonly invitedBy: {
      readonly name: string;
    };
    readonly organization: {
      readonly name: string;
      readonly slug: string;
    };
    readonly role: MemberRole;
  } | null | undefined;
};
export type invitesPageQuery = {
  response: invitesPageQuery$data;
  variables: invitesPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "inviteId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "inviteId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "role",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "invitesPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Invite",
        "kind": "LinkedField",
        "name": "invite",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "invitedBy",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "invitesPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Invite",
        "kind": "LinkedField",
        "name": "invite",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "invitedBy",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v3/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "25d8427889973d933b8e41b7f6bb3ecb",
    "id": null,
    "metadata": {},
    "name": "invitesPageQuery",
    "operationKind": "query",
    "text": "query invitesPageQuery(\n  $inviteId: ID!\n) {\n  invite(id: $inviteId) {\n    role\n    invitedBy {\n      name\n      id\n    }\n    organization {\n      slug\n      name\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "25cbc46e7050e2515b260a0f8e45a246";

export default node;
