/**
 * @generated SignedSource<<08484b3b09c7a28fe814e828e8b6cece>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type proposalPageEditorQuery$variables = {
  proposalId: string;
};
export type proposalPageEditorQuery$data = {
  readonly schemaProposal: {
    readonly author: {
      readonly id: string;
    } | null | undefined;
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"editorContextFragment">;
  };
  readonly viewer: {
    readonly __typename: "User";
    readonly id: string;
  };
};
export type proposalPageEditorQuery = {
  response: proposalPageEditorQuery$data;
  variables: proposalPageEditorQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "proposalId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "viewer",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    (v1/*: any*/)
  ],
  "storageKey": null
},
v3 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "proposalId"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "author",
  "plural": false,
  "selections": [
    (v1/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "proposalPageEditorQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": (v2/*: any*/),
        "action": "THROW",
        "path": "viewer"
      },
      {
        "kind": "RequiredField",
        "field": {
          "alias": null,
          "args": (v3/*: any*/),
          "concreteType": "SchemaProposal",
          "kind": "LinkedField",
          "name": "schemaProposal",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            (v4/*: any*/),
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "editorContextFragment"
            }
          ],
          "storageKey": null
        },
        "action": "THROW",
        "path": "schemaProposal"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "proposalPageEditorQuery",
    "selections": [
      (v2/*: any*/),
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "SchemaProposal",
        "kind": "LinkedField",
        "name": "schemaProposal",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "SchemaProposalSubgraph",
            "kind": "LinkedField",
            "name": "subgraphs",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "sdl",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "943ad215456966c5c6d439f0f720f586",
    "id": null,
    "metadata": {},
    "name": "proposalPageEditorQuery",
    "operationKind": "query",
    "text": "query proposalPageEditorQuery(\n  $proposalId: ID!\n) {\n  viewer {\n    __typename\n    id\n  }\n  schemaProposal(id: $proposalId) {\n    id\n    author {\n      id\n    }\n    ...editorContextFragment\n  }\n}\n\nfragment editorContextFragment on SchemaProposal {\n  subgraphs {\n    name\n    sdl\n  }\n}\n"
  }
};
})();

(node as any).hash = "eb6efd681be1ecf36baa0195c48dad6d";

export default node;
