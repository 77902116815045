/**
 * @generated SignedSource<<086bd1d26e6aef89480acdd736fdf66f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type proposalDiffPageQuery$variables = {
  proposalId: string;
};
export type proposalDiffPageQuery$data = {
  readonly schemaProposal: {
    readonly author: {
      readonly id: string;
    } | null | undefined;
    readonly id: string;
  };
  readonly viewer: {
    readonly __typename: "User";
    readonly id: string;
  };
};
export type proposalDiffPageQuery = {
  response: proposalDiffPageQuery$data;
  variables: proposalDiffPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "proposalId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "viewer",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    (v1/*: any*/)
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "id",
      "variableName": "proposalId"
    }
  ],
  "concreteType": "SchemaProposal",
  "kind": "LinkedField",
  "name": "schemaProposal",
  "plural": false,
  "selections": [
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "author",
      "plural": false,
      "selections": [
        (v1/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "proposalDiffPageQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": (v2/*: any*/),
        "action": "THROW",
        "path": "viewer"
      },
      {
        "kind": "RequiredField",
        "field": (v3/*: any*/),
        "action": "THROW",
        "path": "schemaProposal"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "proposalDiffPageQuery",
    "selections": [
      (v2/*: any*/),
      (v3/*: any*/)
    ]
  },
  "params": {
    "cacheID": "bd47659caeac653fb622b6c481543ca7",
    "id": null,
    "metadata": {},
    "name": "proposalDiffPageQuery",
    "operationKind": "query",
    "text": "query proposalDiffPageQuery(\n  $proposalId: ID!\n) {\n  viewer {\n    __typename\n    id\n  }\n  schemaProposal(id: $proposalId) {\n    id\n    author {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "df0e822f428419ca6f565fd06457a1df";

export default node;
