/**
 * @generated SignedSource<<95a2462d7485a6011207c246289c36cf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type DashboardNewGraphRedirectQuery$variables = Record<PropertyKey, never>;
export type DashboardNewGraphRedirectQuery$data = {
  readonly viewer: {
    readonly personalAccount: {
      readonly slug: string;
    } | null | undefined;
  } | null | undefined;
};
export type DashboardNewGraphRedirectQuery = {
  response: DashboardNewGraphRedirectQuery$data;
  variables: DashboardNewGraphRedirectQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "DashboardNewGraphRedirectQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PersonalAccount",
            "kind": "LinkedField",
            "name": "personalAccount",
            "plural": false,
            "selections": [
              (v0/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "DashboardNewGraphRedirectQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PersonalAccount",
            "kind": "LinkedField",
            "name": "personalAccount",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/)
            ],
            "storageKey": null
          },
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e07dc0ae0569752c384d02a8481c7aa3",
    "id": null,
    "metadata": {},
    "name": "DashboardNewGraphRedirectQuery",
    "operationKind": "query",
    "text": "query DashboardNewGraphRedirectQuery {\n  viewer {\n    personalAccount {\n      slug\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "a86c1e1cfcc821a154828cedc163cd42";

export default node;
