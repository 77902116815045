import node, {
  type subgraphSchemaPageQuery,
} from '#gen/relay/subgraphSchemaPageQuery.graphql'
import { ErrorHandler } from '#lib/errors/errors'
import { lazyLoadedComponent } from '#lib/routing/lazy-loaded-component'
import '@pathfinder-ide/react/dist/style.css'
import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute(
  '/_dashboard/$account/$graph/branches/$branch/schema/sdl/subgraph/$subgraph',
)({
  staticData: { title: '[graph] - SDL - Subgraph' },
  errorComponent: ErrorHandler,
  loader({ context, params }) {
    return context.preload<subgraphSchemaPageQuery>(node, params)
  },
  component: lazyLoadedComponent(
    () => import('#app/pages/branch/schema/subgraph-schema-page'),
    'SubgraphSchemaPage',
  ),
})
