import { useEffect } from 'react'

import { Config } from '#app/config'
import { router } from '#app/router'
import { rudder } from '#app/third-party/tools/rudder'
import * as Sentry from '@sentry/react'
import { Analytics as VercelAnalytics } from '@vercel/analytics/react'
import { SpeedInsights } from '@vercel/speed-insights/react'

import { loadGTM } from './tools/gtm'
import { loadKoala } from './tools/koala'
import { loadLinkedin } from './tools/linkedin'
import { initRudderSdk } from './tools/rudder'

export function setupThirdPartyTools() {
  if (Config.SENTRY?.DSN)
    Sentry.init({
      // Disabled by default in dev environment. Enable temporarily as needed.
      enabled: !import.meta.env.DEV,

      // On Vercel deployments we tunnel requests through the reverse proxy set up
      // at /sentry. This helps avoid ad blockers interfering with Sentry requests.
      tunnel: Config.SENTRY.ENABLE_TUNNEL ? '/sentry' : undefined,

      dsn: Config.SENTRY.DSN,
      environment: Config.ENVIRONMENT,

      integrations: [
        Sentry.replayIntegration({
          networkDetailAllowUrls: [Config.GRAFBASE_GRAPHQL_API_URL],

          // This is a workaround to fix Sentry's replay integration causing error
          // messages with Stripe payment forms. See more details in the following
          // issue: https://github.com/getsentry/sentry-javascript/issues/6560. In
          // order to prevent this error we block Sentry Replay completely on the
          // page as soon as there is a Stripe iframe present somewhere in the body.
          block: ['body:has(iframe[name*="Stripe"])'],
        }),
        Sentry.tanstackRouterBrowserTracingIntegration(router),
      ],

      // 10% of successful sessions, and all error sessions will be captured
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
    })

  initRudderSdk()

  router.subscribe('onBeforeLoad', ({ pathChanged }) => {
    if (pathChanged) {
      rudder?.page()
    }
  })
}

export function ThirdPartyTools() {
  useEffect(() => {
    if (Config.ENABLE_KOALA_ANALYTICS) loadKoala()
    if (Config.ENABLE_LINKEDIN_ANALYTICS) loadLinkedin()
    if (Config.GOOGLE_TAG_MANAGER_CONTAINER_ID)
      loadGTM(Config.GOOGLE_TAG_MANAGER_CONTAINER_ID)
  }, [])

  return (
    <>
      {Config.ENABLE_VERCEL_SPEED_INSIGHTS && <SpeedInsights />}
      {Config.ENABLE_VERCEL_ANALYTICS && <VercelAnalytics />}
      {Config.ENABLE_LINKEDIN_ANALYTICS && (
        <noscript>
          <img
            height="1"
            width="1"
            style={{ display: 'none' }}
            alt=""
            src="https://px.ads.linkedin.com/collect/?pid=5845881&fmt=gif"
          />
        </noscript>
      )}
    </>
  )
}
