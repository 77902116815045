/**
 * @generated SignedSource<<a6df859195ffffe58800bd175748fc35>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GraphMode = "MANAGED" | "SELF_HOSTED" | "%future added value";
export type graphOverviewPageQuery$variables = {
  account: string;
  graph: string;
};
export type graphOverviewPageQuery$data = {
  readonly graph: {
    readonly branches: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly " $fragmentSpreads": FragmentRefs<"branchListItemFragment">;
        };
      }>;
    };
    readonly graphMode: GraphMode;
    readonly productionBranch: {
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"branchListItemFragment">;
    };
  } | null | undefined;
};
export type graphOverviewPageQuery = {
  response: graphOverviewPageQuery$data;
  variables: graphOverviewPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "account"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "graph"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "accountSlug",
    "variableName": "account"
  },
  {
    "kind": "Variable",
    "name": "graphSlug",
    "variableName": "graph"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "graphMode",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  (v3/*: any*/),
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "branchListItemFragment"
  }
],
v5 = [
  (v3/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Deployment",
    "kind": "LinkedField",
    "name": "latestDeployment",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "GitCommit",
        "kind": "LinkedField",
        "name": "commit",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "author",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "authorAvatarUrl",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "createdAt",
        "storageKey": null
      },
      (v3/*: any*/)
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "graphOverviewPageQuery",
    "selections": [
      {
        "alias": "graph",
        "args": (v1/*: any*/),
        "concreteType": "Graph",
        "kind": "LinkedField",
        "name": "graphByAccountSlug",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Branch",
            "kind": "LinkedField",
            "name": "productionBranch",
            "plural": false,
            "selections": (v4/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "BranchConnection",
            "kind": "LinkedField",
            "name": "branches",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "BranchEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Branch",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": (v4/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "graphOverviewPageQuery",
    "selections": [
      {
        "alias": "graph",
        "args": (v1/*: any*/),
        "concreteType": "Graph",
        "kind": "LinkedField",
        "name": "graphByAccountSlug",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Branch",
            "kind": "LinkedField",
            "name": "productionBranch",
            "plural": false,
            "selections": (v5/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "BranchConnection",
            "kind": "LinkedField",
            "name": "branches",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "BranchEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Branch",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": (v5/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "98811394e7774ff0fc8a5cf1a3d97475",
    "id": null,
    "metadata": {},
    "name": "graphOverviewPageQuery",
    "operationKind": "query",
    "text": "query graphOverviewPageQuery(\n  $account: String!\n  $graph: String!\n) {\n  graph: graphByAccountSlug(accountSlug: $account, graphSlug: $graph) {\n    graphMode\n    productionBranch {\n      id\n      ...branchListItemFragment\n    }\n    branches {\n      edges {\n        node {\n          id\n          ...branchListItemFragment\n        }\n      }\n    }\n    id\n  }\n}\n\nfragment branchListItemFragment on Branch {\n  id\n  name\n  latestDeployment {\n    commit {\n      author\n      authorAvatarUrl\n    }\n    createdAt\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "eaefaeab067d58f01e849866c061329f";

export default node;
