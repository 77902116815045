import node, {
  type proposalOverviewPageQuery,
} from '#gen/relay/proposalOverviewPageQuery.graphql'
import { lazyLoadedComponent } from '#lib/routing/lazy-loaded-component'
import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute(
  '/_dashboard/$account/$graph/branches/$branch/proposals/$id/_proposal/overview',
)({
  staticData: { title: '[graph] - Proposal Overview' },
  loader({ context, params }) {
    return context.preload<proposalOverviewPageQuery>(node, {
      accountSlug: params.account,
      graphSlug: params.graph,
      branchName: params.branch,
      proposalId: params.id,
    })
  },
  component: lazyLoadedComponent(
    () =>
      import(
        '../pages/branch/proposals/proposal-page/overview-page/proposal-overview-page'
      ),
    'ProposalOverviewPage',
  ),
})
