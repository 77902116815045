import node, {
  type graphDomainsPageQuery,
} from '#gen/relay/graphDomainsPageQuery.graphql'
import { lazyLoadedComponent } from '#lib/routing/lazy-loaded-component'
import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute(
  '/_dashboard/$account/$graph/branches/$branch/settings/graph/domains',
)({
  staticData: { title: '[graph] - Domains' },
  async loader({ context, params }) {
    return context.preload<graphDomainsPageQuery>(node, params)
  },
  component: lazyLoadedComponent(
    () => import('../pages/settings/graph/domains/graph-domains-page'),
    'GraphDomainsPage',
  ),
})
