import node, {
  type deploymentDetailsPageQuery,
} from '#gen/relay/deploymentDetailsPageQuery.graphql'
import { lazyLoadedComponent } from '#lib/routing/lazy-loaded-component'
import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute(
  '/_dashboard/$account/$graph/branches/$branch/deployments/$id',
)({
  staticData: { title: '[graph] - Deployment' },
  loader({ context, params }) {
    return context.preload<deploymentDetailsPageQuery>(node, {
      ...params,
    })
  },
  component: lazyLoadedComponent(
    () => import('#app/pages/branch/deployments/deployment-details-page'),
    'DeploymentDetailsPage',
  ),
})
