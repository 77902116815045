/**
 * @generated SignedSource<<e7044af1c58cee0ba196dac96fe96a9c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type accountLayoutQuery$variables = Record<PropertyKey, never>;
export type accountLayoutQuery$data = {
  readonly viewer: {
    readonly personalAccount: {
      readonly slug: string;
    } | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"selectsDataWrapperAccountsFragment">;
  };
  readonly " $fragmentSpreads": FragmentRefs<"accountPermissionsFragment">;
};
export type accountLayoutQuery = {
  response: accountLayoutQuery$data;
  variables: accountLayoutQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "accountLayoutQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "viewer",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "PersonalAccount",
              "kind": "LinkedField",
              "name": "personalAccount",
              "plural": false,
              "selections": [
                (v0/*: any*/)
              ],
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "selectsDataWrapperAccountsFragment"
            }
          ],
          "storageKey": null
        },
        "action": "THROW",
        "path": "viewer"
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "accountPermissionsFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "accountLayoutQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PersonalAccount",
            "kind": "LinkedField",
            "name": "personalAccount",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/)
            ],
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "OrganizationConnection",
            "kind": "LinkedField",
            "name": "organizations",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  (v0/*: any*/),
                  (v2/*: any*/),
                  (v1/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Member",
            "kind": "LinkedField",
            "name": "organizationMemberships",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "role",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "account",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  (v1/*: any*/),
                  (v0/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "plan",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v1/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "324303ff567c83c1ee44ff86b5a1fdeb",
    "id": null,
    "metadata": {},
    "name": "accountLayoutQuery",
    "operationKind": "query",
    "text": "query accountLayoutQuery {\n  viewer {\n    personalAccount {\n      slug\n      id\n    }\n    ...selectsDataWrapperAccountsFragment\n    id\n  }\n  ...accountPermissionsFragment\n}\n\nfragment accountPermissionsFragment on Query {\n  viewer {\n    personalAccount {\n      slug\n      id\n    }\n    organizationMemberships {\n      role\n      account {\n        __typename\n        id\n        slug\n        plan\n      }\n      id\n    }\n    id\n  }\n}\n\nfragment accountSelectFragment on User {\n  name\n  personalAccount {\n    slug\n    id\n  }\n  organizations {\n    nodes {\n      slug\n      name\n      id\n    }\n  }\n}\n\nfragment selectsDataWrapperAccountsFragment on User {\n  ...accountSelectFragment\n}\n"
  }
};
})();

(node as any).hash = "09b9f68d19473f6f52fd2b60b67bd1d5";

export default node;
