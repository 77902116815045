/**
 * @generated SignedSource<<567ece962f930138399b8c92a3a9ec35>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SchemaProposalStatus = "APPROVED" | "DRAFT" | "IMPLEMENTED" | "IN_REVIEW" | "REJECTED" | "%future added value";
export type proposalOverviewPageQuery$variables = {
  accountSlug: string;
  branchName: string;
  graphSlug: string;
  proposalId: string;
};
export type proposalOverviewPageQuery$data = {
  readonly accountBySlug: {
    readonly " $fragmentSpreads": FragmentRefs<"reviewersCardFragment">;
  };
  readonly branch: {
    readonly id: string;
  };
  readonly schemaProposal: {
    readonly author: {
      readonly avatarUrl: string | null | undefined;
      readonly id: string;
    } | null | undefined;
    readonly description: string | null | undefined;
    readonly id: string;
    readonly status: SchemaProposalStatus;
  };
  readonly viewer: {
    readonly avatarUrl: string | null | undefined;
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"eventsTimelineProposalFragment">;
  };
};
export type proposalOverviewPageQuery = {
  response: proposalOverviewPageQuery$data;
  variables: proposalOverviewPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "accountSlug"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "branchName"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "graphSlug"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "proposalId"
},
v4 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "accountSlug"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "avatarUrl",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "accountSlug",
      "variableName": "accountSlug"
    },
    {
      "kind": "Variable",
      "name": "graphSlug",
      "variableName": "graphSlug"
    },
    {
      "kind": "Variable",
      "name": "name",
      "variableName": "branchName"
    }
  ],
  "concreteType": "Branch",
  "kind": "LinkedField",
  "name": "branch",
  "plural": false,
  "selections": [
    (v5/*: any*/)
  ],
  "storageKey": null
},
v8 = [
  (v5/*: any*/),
  (v6/*: any*/)
],
v9 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "id",
      "variableName": "proposalId"
    }
  ],
  "concreteType": "SchemaProposal",
  "kind": "LinkedField",
  "name": "schemaProposal",
  "plural": false,
  "selections": [
    (v5/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "author",
      "plural": false,
      "selections": (v8/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "proposalOverviewPageQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "alias": null,
          "args": (v4/*: any*/),
          "concreteType": null,
          "kind": "LinkedField",
          "name": "accountBySlug",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "reviewersCardFragment"
            }
          ],
          "storageKey": null
        },
        "action": "THROW",
        "path": "accountBySlug"
      },
      {
        "kind": "RequiredField",
        "field": {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "viewer",
          "plural": false,
          "selections": [
            (v5/*: any*/),
            (v6/*: any*/),
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "eventsTimelineProposalFragment"
            }
          ],
          "storageKey": null
        },
        "action": "THROW",
        "path": "viewer"
      },
      {
        "kind": "RequiredField",
        "field": (v7/*: any*/),
        "action": "THROW",
        "path": "branch"
      },
      {
        "kind": "RequiredField",
        "field": (v9/*: any*/),
        "action": "THROW",
        "path": "schemaProposal"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "proposalOverviewPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "accountBySlug",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "MemberConnection",
                "kind": "LinkedField",
                "name": "members",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Member",
                    "kind": "LinkedField",
                    "name": "nodes",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "User",
                        "kind": "LinkedField",
                        "name": "user",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          (v10/*: any*/),
                          (v6/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "TeamConnection",
                "kind": "LinkedField",
                "name": "teams",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Team",
                    "kind": "LinkedField",
                    "name": "nodes",
                    "plural": true,
                    "selections": [
                      (v5/*: any*/),
                      (v10/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Organization",
            "abstractKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": (v8/*: any*/),
        "storageKey": null
      },
      (v7/*: any*/),
      (v9/*: any*/)
    ]
  },
  "params": {
    "cacheID": "17eb1e7d18f5a98bb09a9e989732eb77",
    "id": null,
    "metadata": {},
    "name": "proposalOverviewPageQuery",
    "operationKind": "query",
    "text": "query proposalOverviewPageQuery(\n  $accountSlug: String!\n  $graphSlug: String!\n  $branchName: String!\n  $proposalId: ID!\n) {\n  accountBySlug(slug: $accountSlug) {\n    __typename\n    ...reviewersCardFragment\n    id\n  }\n  viewer {\n    id\n    avatarUrl\n    ...eventsTimelineProposalFragment\n  }\n  branch(accountSlug: $accountSlug, graphSlug: $graphSlug, name: $branchName) {\n    id\n  }\n  schemaProposal(id: $proposalId) {\n    id\n    author {\n      id\n      avatarUrl\n    }\n    description\n    status\n  }\n}\n\nfragment eventsTimelineProposalFragment on User {\n  avatarUrl\n}\n\nfragment reviewersCardFragment on Organization {\n  members {\n    nodes {\n      user {\n        id\n        name\n        avatarUrl\n      }\n      id\n    }\n  }\n  teams {\n    nodes {\n      id\n      name\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f846d6cabbb834f212e00f401ea273d8";

export default node;
