/**
 * @generated SignedSource<<6e77730f3e38528d1056159faa60a6ce>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type MemberRole = "ADMIN" | "MEMBER" | "OWNER" | "%future added value";
export type personalDangerZonePageQuery$variables = Record<PropertyKey, never>;
export type personalDangerZonePageQuery$data = {
  readonly viewer: {
    readonly organizationMemberships: ReadonlyArray<{
      readonly role: MemberRole;
    }>;
    readonly personalAccount: {
      readonly slug: string;
    } | null | undefined;
  } | null | undefined;
};
export type personalDangerZonePageQuery = {
  response: personalDangerZonePageQuery$data;
  variables: personalDangerZonePageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "role",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "personalDangerZonePageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PersonalAccount",
            "kind": "LinkedField",
            "name": "personalAccount",
            "plural": false,
            "selections": [
              (v0/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Member",
            "kind": "LinkedField",
            "name": "organizationMemberships",
            "plural": true,
            "selections": [
              (v1/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "personalDangerZonePageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PersonalAccount",
            "kind": "LinkedField",
            "name": "personalAccount",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Member",
            "kind": "LinkedField",
            "name": "organizationMemberships",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "37055868bc2287896fa5bf0f10200d2d",
    "id": null,
    "metadata": {},
    "name": "personalDangerZonePageQuery",
    "operationKind": "query",
    "text": "query personalDangerZonePageQuery {\n  viewer {\n    personalAccount {\n      slug\n      id\n    }\n    organizationMemberships {\n      role\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "9502e17640c3e1d981248a796939dc11";

export default node;
