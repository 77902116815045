import type { integrationsPageQuery } from '#gen/relay/integrationsPageQuery.graphql'
import node from '#gen/relay/integrationsPageQuery.graphql'
import { lazyLoadedComponent } from '#lib/routing/lazy-loaded-component'
import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute(
  '/_dashboard/$account/_account/settings/integrations',
)({
  staticData: { title: 'Integrations' },
  loader({ context, params }) {
    return context.preload<integrationsPageQuery>(node, params)
  },
  component: lazyLoadedComponent(
    () => import('#app/pages/account/integrations-page'),
    'IntegrationsPage',
  ),
})
