import node, {
  type graphOverviewPageQuery,
} from '#gen/relay/graphOverviewPageQuery.graphql'
import { lazyLoadedComponent } from '#lib/routing/lazy-loaded-component'
import { FullpageSpinner } from '#lib/ui/loaders/spinner'
import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute('/_dashboard/$account/$graph/')({
  staticData: { title: '[graph] - Branches' },
  async loader({ context, params }) {
    return context.preload<graphOverviewPageQuery>(node, {
      account: params.account,
      graph: params.graph,
    })
  },
  wrapInSuspense: true,
  pendingComponent: () => (
    <div style={{ gridRow: 'secondary', gridColumn: 'content' }}>
      <FullpageSpinner />
    </div>
  ),
  component: lazyLoadedComponent(
    () => import('#app/pages/graph/graph-overview-page'),
    'GraphOverviewPage',
  ),
})
