/**
 * @generated SignedSource<<22b1621bfaa55d9f3f6364eab5fc5958>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type deploymentDetailsPageQuery$variables = {
  id: string;
};
export type deploymentDetailsPageQuery$data = {
  readonly deployment: {
    readonly createdAt: string;
    readonly " $fragmentSpreads": FragmentRefs<"commitDetailsDeploymentFragment" | "deploymentDetailsPageStatusFragment">;
  } | null | undefined;
};
export type deploymentDetailsPageQuery = {
  response: deploymentDetailsPageQuery$data;
  variables: deploymentDetailsPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "deploymentDetailsPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Deployment",
        "kind": "LinkedField",
        "name": "deployment",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "deploymentDetailsPageStatusFragment"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "commitDetailsDeploymentFragment"
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "deploymentDetailsPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Deployment",
        "kind": "LinkedField",
        "name": "deployment",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "duration",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "DeploymentLogEntry",
            "kind": "LinkedField",
            "name": "logEntries",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "GitCommit",
            "kind": "LinkedField",
            "name": "commit",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "sha",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "triggerType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Graph",
            "kind": "LinkedField",
            "name": "graph",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "GitRepository",
                "kind": "LinkedField",
                "name": "repository",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "url",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7e6008df03e03781147d55f1f6d1d639",
    "id": null,
    "metadata": {},
    "name": "deploymentDetailsPageQuery",
    "operationKind": "query",
    "text": "query deploymentDetailsPageQuery(\n  $id: ID!\n) {\n  deployment(id: $id) {\n    ...deploymentDetailsPageStatusFragment\n    ...commitDetailsDeploymentFragment\n    createdAt\n    id\n  }\n}\n\nfragment commitDetailsDeploymentFragment on Deployment {\n  commit {\n    message\n    sha\n  }\n  triggerType\n  graph {\n    repository {\n      url\n    }\n    id\n  }\n}\n\nfragment deploymentDetailsPageStatusFragment on Deployment {\n  status\n  duration\n  logEntries {\n    createdAt\n    message\n  }\n  id\n}\n"
  }
};
})();

(node as any).hash = "62ae36c1efbf5e59c3df08eb581cdf99";

export default node;
