import node, {
  type branchLayoutQuery,
} from '#gen/relay/branchLayoutQuery.graphql'
import { lazyLoadedComponent } from '#lib/routing/lazy-loaded-component'
import { FullpageSpinner } from '#lib/ui/loaders/spinner'
import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute(
  '/_dashboard/$account/$graph/branches/$branch',
)({
  staticData: { title: '' },

  // Branch name can contain slashes, @-characters and other things that
  // can mess up our URL parsing, so we need to encode/decode it.
  stringifyParams(params) {
    return { ...params, branch: encodeURIComponent(params.branch) }
  },
  parseParams(params) {
    return { ...params, branch: decodeURIComponent(params.branch) }
  },
  async loader({ context, params }) {
    return context.preload<branchLayoutQuery>(node, {
      account: params.account,
      graph: params.graph,
    })
  },
  wrapInSuspense: true,
  pendingComponent: () => (
    <div style={{ gridRow: 'secondary', gridColumn: 'content' }}>
      <FullpageSpinner />
    </div>
  ),
  component: lazyLoadedComponent(
    () => import('#app/pages/branch/branch-layout'),
    'BranchLayout',
  ),
})
