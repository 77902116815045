/**
 * @generated SignedSource<<aafae7599752c492b06a8169faa85959>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BranchEnvironment = "PREVIEW" | "PRODUCTION" | "%future added value";
export type DatadogRegion = "AP1" | "EU1" | "US1" | "US1_FED" | "US3" | "US5" | "%future added value";
export type integrationsPageQuery$variables = {
  account: string;
};
export type integrationsPageQuery$data = {
  readonly account: {
    readonly accountIntegrations?: ReadonlyArray<{
      readonly __typename: "AccountIntegration";
      readonly attributes: {
        readonly __typename: "AccountDopplerIntegration";
        readonly accessToken: string;
      } | {
        readonly __typename: "DatadogIntegration";
        readonly apiKey: string;
        readonly region: DatadogRegion;
        readonly sampling: number;
      } | {
        // This will never be '%other', but we need some
        // value in case none of the concrete values match.
        readonly __typename: "%other";
      };
      readonly environments: ReadonlyArray<BranchEnvironment>;
      readonly graphIntegrations: ReadonlyArray<{
        readonly __typename: "GraphIntegration";
        readonly attributes: {
          readonly __typename: "ProjectDopplerIntegration";
          readonly __typename: "ProjectDopplerIntegration";
        } | {
          // This will never be '%other', but we need some
          // value in case none of the concrete values match.
          readonly __typename: "%other";
        } | null | undefined;
        readonly graph: {
          readonly id: string;
        };
        readonly id: string;
      }>;
      readonly id: string;
    }>;
    readonly graphs: {
      readonly nodes: ReadonlyArray<{
        readonly id: string;
        readonly slug: string;
      }>;
    };
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"dopplerMappingsModalFragment">;
  } | null | undefined;
};
export type integrationsPageQuery = {
  response: integrationsPageQuery$data;
  variables: integrationsPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "account"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "account"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "slug",
    "storageKey": null
  }
],
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "GraphConnection",
  "kind": "LinkedField",
  "name": "graphs",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Graph",
      "kind": "LinkedField",
      "name": "nodes",
      "plural": true,
      "selections": (v3/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "environments",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": null,
  "kind": "LinkedField",
  "name": "attributes",
  "plural": false,
  "selections": [
    (v5/*: any*/),
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "apiKey",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "region",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "sampling",
          "storageKey": null
        }
      ],
      "type": "DatadogIntegration",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "accessToken",
          "storageKey": null
        }
      ],
      "type": "AccountDopplerIntegration",
      "abstractKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "integrationsPageQuery",
    "selections": [
      {
        "alias": "account",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "accountBySlug",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v4/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "dopplerMappingsModalFragment"
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": "accountIntegrations",
                "args": null,
                "concreteType": "AccountIntegration",
                "kind": "LinkedField",
                "name": "integrations",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  {
                    "alias": "graphIntegrations",
                    "args": null,
                    "concreteType": "GraphIntegration",
                    "kind": "LinkedField",
                    "name": "graphs",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "attributes",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Graph",
                        "kind": "LinkedField",
                        "name": "graph",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Organization",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "integrationsPageQuery",
    "selections": [
      {
        "alias": "account",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "accountBySlug",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          {
            "kind": "TypeDiscriminator",
            "abstractKey": "__isAccount"
          },
          (v4/*: any*/),
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": "accountIntegrations",
                "args": null,
                "concreteType": "AccountIntegration",
                "kind": "LinkedField",
                "name": "integrations",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": "graphIntegrations",
                    "args": null,
                    "concreteType": "GraphIntegration",
                    "kind": "LinkedField",
                    "name": "graphs",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "attributes",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "configName",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "projectName",
                                "storageKey": null
                              }
                            ],
                            "type": "ProjectDopplerIntegration",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Graph",
                        "kind": "LinkedField",
                        "name": "graph",
                        "plural": false,
                        "selections": (v3/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v7/*: any*/),
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "Organization",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "654dc123ffb06956a5b1b8bb686de694",
    "id": null,
    "metadata": {},
    "name": "integrationsPageQuery",
    "operationKind": "query",
    "text": "query integrationsPageQuery(\n  $account: String!\n) {\n  account: accountBySlug(slug: $account) {\n    __typename\n    ...dopplerMappingsModalFragment\n    id\n    graphs {\n      nodes {\n        id\n        slug\n      }\n    }\n    ... on Organization {\n      accountIntegrations: integrations {\n        id\n        __typename\n        environments\n        attributes {\n          __typename\n          ... on DatadogIntegration {\n            apiKey\n            region\n            sampling\n          }\n          ... on AccountDopplerIntegration {\n            accessToken\n          }\n        }\n        graphIntegrations: graphs {\n          id\n          __typename\n          attributes {\n            __typename\n            ... on ProjectDopplerIntegration {\n              __typename\n            }\n          }\n          graph {\n            id\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment dopplerMappingsModalFragment on Account {\n  __isAccount: __typename\n  graphs {\n    nodes {\n      id\n      slug\n    }\n  }\n  ... on Organization {\n    accountIntegrations: integrations {\n      id\n      __typename\n      graphIntegrations: graphs {\n        id\n        __typename\n        attributes {\n          __typename\n          ... on ProjectDopplerIntegration {\n            __typename\n            configName\n            projectName\n          }\n        }\n        graph {\n          id\n          slug\n        }\n      }\n      attributes {\n        __typename\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5d9b7d0007474387faf79bba5e85d26c";

export default node;
