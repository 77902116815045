import node, { type logsPageQuery } from '#gen/relay/logsPageQuery.graphql'
import { lazyLoadedComponent } from '#lib/routing/lazy-loaded-component'
import { createFileRoute } from '@tanstack/react-router'

const PAGE_SIZE = 30

export const Route = createFileRoute(
  '/_dashboard/$account/$graph/branches/$branch/logs',
)({
  staticData: { title: '[graph] - Logs' },
  loader({ context, params }) {
    return context.preload<logsPageQuery>(node, {
      ...params,
      last: PAGE_SIZE,
    })
  },
  component: lazyLoadedComponent(
    () => import('#app/pages/branch/logs-page'),
    'LogsPage',
  ),
})
