import { Config } from '#app/config'
import {
  UsagePageSearchParamsSchema,
  createUsagePageQueryVariables,
} from '#app/pages/usage/usage'
import node, {
  type accountUsagePageQuery,
} from '#gen/relay/accountUsagePageQuery.graphql'
import { lazyLoadedComponent } from '#lib/routing/lazy-loaded-component'
import { createFileRoute, notFound } from '@tanstack/react-router'
import { parse } from 'valibot'

export const Route = createFileRoute(
  '/_dashboard/$account/_account/settings/usage',
)({
  staticData: { title: 'Usage' },
  validateSearch(search) {
    return parse(UsagePageSearchParamsSchema, search)
  },
  loaderDeps(opts) {
    return {
      search: opts.search,
    }
  },
  async loader({ context, deps, params }) {
    if (Config.IS_ENTERPRISE_BUILD) throw notFound()

    return context.preload<accountUsagePageQuery>(node, {
      ...createUsagePageQueryVariables(deps.search),
      account: params.account,
    })
  },
  component: lazyLoadedComponent(
    () => import('#app/pages/usage/account-usage-page'),
    'AccountUsagePage',
  ),
})
