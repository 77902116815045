import { lazyLoadedComponent } from '#lib/routing/lazy-loaded-component'
import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute('/admin')({
  staticData: { title: 'Instance admin' },
  component: lazyLoadedComponent(
    () => import('#app/pages/admin/admin-page'),
    'AdminPage',
  ),
  async loader() {
    return fetch('/_auth/zitadel-status').then<{
      resources: boolean
      idp: boolean
    }>(res => res.json())
  },
})
