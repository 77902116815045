/**
 * @generated SignedSource<<cbd67e326273508a5955595414929e76>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type proposalsSettingsPageQuery$variables = {
  account: string;
  graph: string;
};
export type proposalsSettingsPageQuery$data = {
  readonly account: {
    readonly " $fragmentSpreads": FragmentRefs<"proposalsSettingsPageDefaultReviewersFragment">;
  } | null | undefined;
  readonly graph: {
    readonly id: string;
  } | null | undefined;
};
export type proposalsSettingsPageQuery = {
  response: proposalsSettingsPageQuery$data;
  variables: proposalsSettingsPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "account"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "graph"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "account"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": "graph",
  "args": [
    {
      "kind": "Variable",
      "name": "accountSlug",
      "variableName": "account"
    },
    {
      "kind": "Variable",
      "name": "graphSlug",
      "variableName": "graph"
    }
  ],
  "concreteType": "Graph",
  "kind": "LinkedField",
  "name": "graphByAccountSlug",
  "plural": false,
  "selections": [
    (v2/*: any*/)
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "proposalsSettingsPageQuery",
    "selections": [
      {
        "alias": "account",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "accountBySlug",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "proposalsSettingsPageDefaultReviewersFragment"
          }
        ],
        "storageKey": null
      },
      (v3/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "proposalsSettingsPageQuery",
    "selections": [
      {
        "alias": "account",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "accountBySlug",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "MemberConnection",
                "kind": "LinkedField",
                "name": "members",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Member",
                    "kind": "LinkedField",
                    "name": "nodes",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "User",
                        "kind": "LinkedField",
                        "name": "user",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v4/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "avatarUrl",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "TeamConnection",
                "kind": "LinkedField",
                "name": "teams",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Team",
                    "kind": "LinkedField",
                    "name": "nodes",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Organization",
            "abstractKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      },
      (v3/*: any*/)
    ]
  },
  "params": {
    "cacheID": "6fa5781396ada7c0766303abb25ffc73",
    "id": null,
    "metadata": {},
    "name": "proposalsSettingsPageQuery",
    "operationKind": "query",
    "text": "query proposalsSettingsPageQuery(\n  $account: String!\n  $graph: String!\n) {\n  account: accountBySlug(slug: $account) {\n    __typename\n    ...proposalsSettingsPageDefaultReviewersFragment\n    id\n  }\n  graph: graphByAccountSlug(accountSlug: $account, graphSlug: $graph) {\n    id\n  }\n}\n\nfragment proposalsSettingsPageDefaultReviewersFragment on Organization {\n  members {\n    nodes {\n      user {\n        id\n        name\n        avatarUrl\n      }\n      id\n    }\n  }\n  teams {\n    nodes {\n      id\n      name\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "02af3c5aa6b43f10e840069ed23b3255";

export default node;
