import node, {
  type proposalsSettingsPageQuery,
} from '#gen/relay/proposalsSettingsPageQuery.graphql'
import { lazyLoadedComponent } from '#lib/routing/lazy-loaded-component'
import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute(
  '/_dashboard/$account/$graph/branches/$branch/settings/graph/proposals',
)({
  staticData: { title: '[graph] - Proposals' },
  async loader({ context, params }) {
    return context.preload<proposalsSettingsPageQuery>(node, params)
  },
  component: lazyLoadedComponent(
    () =>
      import('../pages/settings/graph/proposals/proposals-settings-page.js'),
    'ProposalsSettingsPage',
  ),
})
