/**
 * @generated SignedSource<<f24a19947d869a56e8f08a1b6438a06e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type newGraphPageQuery$variables = {
  slug: string;
};
export type newGraphPageQuery$data = {
  readonly account: {
    readonly id: string;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"newGraphPageFragment">;
};
export type newGraphPageQuery = {
  response: newGraphPageQuery$data;
  variables: newGraphPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "slug"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "slug"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "newGraphPageQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "newGraphPageFragment"
      },
      {
        "alias": "account",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "accountBySlug",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "newGraphPageQuery",
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "provider",
            "value": "GITHUB"
          }
        ],
        "concreteType": null,
        "kind": "LinkedField",
        "name": "gitAccounts",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "GitAccount",
                "kind": "LinkedField",
                "name": "accounts",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "slug",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "type",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "GitAccountsSuccess",
            "abstractKey": null
          }
        ],
        "storageKey": "gitAccounts(provider:\"GITHUB\")"
      },
      {
        "alias": "account",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "accountBySlug",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "41351dfce536847d79b5c23816dc3cd5",
    "id": null,
    "metadata": {},
    "name": "newGraphPageQuery",
    "operationKind": "query",
    "text": "query newGraphPageQuery(\n  $slug: String!\n) {\n  ...newGraphPageFragment\n  account: accountBySlug(slug: $slug) {\n    __typename\n    id\n  }\n}\n\nfragment newGraphPageFragment on Query {\n  gitAccounts(provider: GITHUB) {\n    __typename\n    ... on GitAccountsSuccess {\n      __typename\n      accounts {\n        id\n        slug\n        type\n      }\n    }\n    ... on GitHubAuthorizationError {\n      __typename\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f7c208b7a730aa00f16442b4a775e680";

export default node;
