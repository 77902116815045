import node, {
  type proposalPageLayoutQuery,
} from '#gen/relay/proposalPageLayoutQuery.graphql'
import { lazyLoadedComponent } from '#lib/routing/lazy-loaded-component'
import { createFileRoute } from '@tanstack/react-router'
import { object, optional, parse, picklist } from 'valibot'

const SearchParamsSchema = object({
  mode: optional(picklist(['reviewer', 'editor'])),
})

export const Route = createFileRoute(
  '/_dashboard/$account/$graph/branches/$branch/proposals/$id/_proposal',
)({
  staticData: { title: 'Proposal Page Layout' },
  validateSearch(search) {
    return parse(SearchParamsSchema, search)
  },
  loader({ context, params }) {
    return context.preload<proposalPageLayoutQuery>(node, {
      proposalId: params.id,
    })
  },
  component: lazyLoadedComponent(
    () =>
      import('../pages/branch/proposals/proposal-page/proposal-page-layout'),
    'ProposalPageLayout',
  ),
})
