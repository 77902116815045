/**
 * @generated SignedSource<<1a5a49201c937b43a3fb17b5e7c2ea96>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type logsPageQuery$variables = {
  account: string;
  before?: string | null | undefined;
  branch: string;
  graph: string;
  last?: number | null | undefined;
};
export type logsPageQuery$data = {
  readonly graphByAccountSlug: {
    readonly " $fragmentSpreads": FragmentRefs<"logsPageListFragment">;
  } | null | undefined;
};
export type logsPageQuery = {
  response: logsPageQuery$data;
  variables: logsPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "account"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "before"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "branch"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "graph"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "last"
},
v5 = [
  {
    "kind": "Variable",
    "name": "accountSlug",
    "variableName": "account"
  },
  {
    "kind": "Variable",
    "name": "graphSlug",
    "variableName": "graph"
  }
],
v6 = {
  "kind": "Variable",
  "name": "before",
  "variableName": "before"
},
v7 = {
  "kind": "Variable",
  "name": "branch",
  "variableName": "branch"
},
v8 = {
  "kind": "Variable",
  "name": "last",
  "variableName": "last"
},
v9 = [
  (v6/*: any*/),
  {
    "fields": [
      (v7/*: any*/)
    ],
    "kind": "ObjectValue",
    "name": "filter"
  },
  (v8/*: any*/)
],
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "logsPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "Graph",
        "kind": "LinkedField",
        "name": "graphByAccountSlug",
        "plural": false,
        "selections": [
          {
            "args": [
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "logsPageListFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/),
      (v4/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "logsPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "Graph",
        "kind": "LinkedField",
        "name": "graphByAccountSlug",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v9/*: any*/),
            "concreteType": "LogEventConnection",
            "kind": "LinkedField",
            "name": "logEvents",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "startCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasPreviousPage",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "LogEventEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      },
                      (v10/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "createdAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "logLevel",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "region",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "environment",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "deploymentId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "message",
                        "storageKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "alias": "functionLogUrl",
                            "args": null,
                            "kind": "ScalarField",
                            "name": "url",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "functionKind",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "functionName",
                            "storageKey": null
                          }
                        ],
                        "type": "FunctionLogEvent",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "url",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "duration",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "httpStatus",
                            "storageKey": null
                          }
                        ],
                        "type": "GatewayRequestLogEvent",
                        "abstractKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v9/*: any*/),
            "filters": [
              "filter"
            ],
            "handle": "connection",
            "key": "logsPageListFragment_logEvents",
            "kind": "LinkedHandle",
            "name": "logEvents"
          },
          (v10/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d23e24ce6525064394d4968cc53c17ff",
    "id": null,
    "metadata": {},
    "name": "logsPageQuery",
    "operationKind": "query",
    "text": "query logsPageQuery(\n  $account: String!\n  $graph: String!\n  $branch: String!\n  $last: Int\n  $before: String\n) {\n  graphByAccountSlug(accountSlug: $account, graphSlug: $graph) {\n    ...logsPageListFragment_1aAbgz\n    id\n  }\n}\n\nfragment logsPageListFragment_1aAbgz on Graph {\n  logEvents(last: $last, before: $before, filter: {branch: $branch}) {\n    pageInfo {\n      startCursor\n      hasPreviousPage\n      endCursor\n      hasNextPage\n    }\n    edges {\n      node {\n        __typename\n        id\n        createdAt\n        logLevel\n        region\n        environment\n        deploymentId\n        message\n        ... on FunctionLogEvent {\n          __typename\n          functionLogUrl: url\n          functionKind\n          functionName\n        }\n        ... on GatewayRequestLogEvent {\n          __typename\n          url\n          duration\n          httpStatus\n        }\n      }\n      cursor\n    }\n  }\n  id\n}\n"
  }
};
})();

(node as any).hash = "dcccc9a357e358452054cb9d9eb271cb";

export default node;
