/**
 * @generated SignedSource<<d8027a1e2fc516d0c45e1b2d742020ac>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type federatedSchemaPageQuery$variables = {
  account: string;
  branch: string;
  graph: string;
};
export type federatedSchemaPageQuery$data = {
  readonly branch: {
    readonly activeDeployment: {
      readonly finishedAt: string | null | undefined;
    } | null | undefined;
    readonly domains: ReadonlyArray<string>;
    readonly federatedSchema: string | null | undefined;
  } | null | undefined;
};
export type federatedSchemaPageQuery = {
  response: federatedSchemaPageQuery$data;
  variables: federatedSchemaPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "account"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "branch"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "graph"
},
v3 = [
  {
    "kind": "Variable",
    "name": "accountSlug",
    "variableName": "account"
  },
  {
    "kind": "Variable",
    "name": "graphSlug",
    "variableName": "graph"
  },
  {
    "kind": "Variable",
    "name": "name",
    "variableName": "branch"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "federatedSchema",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "domains",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "finishedAt",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "federatedSchemaPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Branch",
        "kind": "LinkedField",
        "name": "branch",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Deployment",
            "kind": "LinkedField",
            "name": "activeDeployment",
            "plural": false,
            "selections": [
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "federatedSchemaPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Branch",
        "kind": "LinkedField",
        "name": "branch",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Deployment",
            "kind": "LinkedField",
            "name": "activeDeployment",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              (v7/*: any*/)
            ],
            "storageKey": null
          },
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0a3cd1146b28f2eaae7e179d2ac8bd2f",
    "id": null,
    "metadata": {},
    "name": "federatedSchemaPageQuery",
    "operationKind": "query",
    "text": "query federatedSchemaPageQuery(\n  $account: String!\n  $graph: String!\n  $branch: String!\n) {\n  branch(accountSlug: $account, graphSlug: $graph, name: $branch) {\n    federatedSchema\n    domains\n    activeDeployment {\n      finishedAt\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "4c742b3bd7ca20a87c06fe9e8894b36d";

export default node;
