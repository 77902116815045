/**
 * @generated SignedSource<<b8503d6dedda4a52bf4353192cb27856>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type graphApiKeysPageQuery$variables = {
  account: string;
  graph: string;
};
export type graphApiKeysPageQuery$data = {
  readonly graph: {
    readonly apiKeys: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly " $fragmentSpreads": FragmentRefs<"graphApiKeysPageRowFragment">;
        };
      }>;
    };
    readonly id: string;
  } | null | undefined;
};
export type graphApiKeysPageQuery = {
  response: graphApiKeysPageQuery$data;
  variables: graphApiKeysPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "account"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "graph"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "accountSlug",
    "variableName": "account"
  },
  {
    "kind": "Variable",
    "name": "graphSlug",
    "variableName": "graph"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "graphApiKeysPageQuery",
    "selections": [
      {
        "alias": "graph",
        "args": (v1/*: any*/),
        "concreteType": "Graph",
        "kind": "LinkedField",
        "name": "graphByAccountSlug",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "GraphApiKeyConnection",
            "kind": "LinkedField",
            "name": "apiKeys",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "GraphApiKeyEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "GraphApiKey",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "graphApiKeysPageRowFragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "graphApiKeysPageQuery",
    "selections": [
      {
        "alias": "graph",
        "args": (v1/*: any*/),
        "concreteType": "Graph",
        "kind": "LinkedField",
        "name": "graphByAccountSlug",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "GraphApiKeyConnection",
            "kind": "LinkedField",
            "name": "apiKeys",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "GraphApiKeyEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "GraphApiKey",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "key",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "createdAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "environment",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "58cb294861c00de9b05019a6fc166d2e",
    "id": null,
    "metadata": {},
    "name": "graphApiKeysPageQuery",
    "operationKind": "query",
    "text": "query graphApiKeysPageQuery(\n  $account: String!\n  $graph: String!\n) {\n  graph: graphByAccountSlug(accountSlug: $account, graphSlug: $graph) {\n    id\n    apiKeys {\n      edges {\n        node {\n          id\n          ...graphApiKeysPageRowFragment\n        }\n      }\n    }\n  }\n}\n\nfragment graphApiKeysPageRowFragment on GraphApiKey {\n  id\n  key\n  name\n  createdAt\n  environment\n}\n"
  }
};
})();

(node as any).hash = "420bbcfc171bec6d1017d9506a6bebcc";

export default node;
