import node, {
  type schemaDocumentationPageQuery,
} from '#gen/relay/schemaDocumentationPageQuery.graphql'
import { ErrorHandler } from '#lib/errors/errors'
import { lazyLoadedComponent } from '#lib/routing/lazy-loaded-component'
import '@pathfinder-ide/react/dist/style.css'
import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute(
  '/_dashboard/$account/$graph/branches/$branch/schema/',
)({
  staticData: { title: '[graph] - Schema' },
  errorComponent: ErrorHandler,

  loader({ context, params }) {
    return context.preload<schemaDocumentationPageQuery>(node, params)
  },
  component: lazyLoadedComponent(
    () => import('#app/pages/branch/schema/schema-documentation-page'),
    'SchemaDocumentationPage',
  ),
})
