/**
 * @generated SignedSource<<3c3ea95e8a183361bec9d6da2c19aa2e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LimitCycleChoice = "CURRENT" | "PREVIOUS" | "%future added value";
export type personalUsagePageQuery$variables = {
  cycle?: LimitCycleChoice | null | undefined;
  endDate?: string | null | undefined;
  graphIds?: ReadonlyArray<string> | null | undefined;
  startDate?: string | null | undefined;
};
export type personalUsagePageQuery$data = {
  readonly viewer: {
    readonly personalAccount: {
      readonly " $fragmentSpreads": FragmentRefs<"usageControlsFragment" | "usageDataFragment">;
    } | null | undefined;
  } | null | undefined;
};
export type personalUsagePageQuery = {
  response: personalUsagePageQuery$data;
  variables: personalUsagePageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cycle"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "endDate"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "graphIds"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startDate"
},
v4 = {
  "kind": "Variable",
  "name": "endDate",
  "variableName": "endDate"
},
v5 = {
  "kind": "Variable",
  "name": "graphIds",
  "variableName": "graphIds"
},
v6 = {
  "kind": "Variable",
  "name": "startDate",
  "variableName": "startDate"
},
v7 = [
  {
    "kind": "Variable",
    "name": "cycle",
    "variableName": "cycle"
  },
  (v4/*: any*/),
  (v5/*: any*/),
  (v6/*: any*/)
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v9 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "unit",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "TimeSeriesDataPoint",
    "kind": "LinkedField",
    "name": "data",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "value",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "datetime",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v10 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "used",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "limit",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "personalUsagePageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PersonalAccount",
            "kind": "LinkedField",
            "name": "personalAccount",
            "plural": false,
            "selections": [
              {
                "args": (v7/*: any*/),
                "kind": "FragmentSpread",
                "name": "usageControlsFragment"
              },
              {
                "args": (v7/*: any*/),
                "kind": "FragmentSpread",
                "name": "usageDataFragment"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "personalUsagePageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PersonalAccount",
            "kind": "LinkedField",
            "name": "personalAccount",
            "plural": false,
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "GraphConnection",
                    "kind": "LinkedField",
                    "name": "graphs",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Graph",
                        "kind": "LinkedField",
                        "name": "nodes",
                        "plural": true,
                        "selections": [
                          (v8/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "slug",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": [
                      {
                        "fields": [
                          (v4/*: any*/),
                          (v5/*: any*/),
                          {
                            "kind": "Variable",
                            "name": "limitCycleChoice",
                            "variableName": "cycle"
                          },
                          (v6/*: any*/)
                        ],
                        "kind": "ObjectValue",
                        "name": "filter"
                      }
                    ],
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "resourcesUsageDashboard",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "periodStart",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "periodEnd",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "AccountResourcesUsage",
                            "kind": "LinkedField",
                            "name": "usage",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "TimeSeries",
                                "kind": "LinkedField",
                                "name": "requestCount",
                                "plural": false,
                                "selections": (v9/*: any*/),
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "TimeSeries",
                                "kind": "LinkedField",
                                "name": "udfRequestCount",
                                "plural": false,
                                "selections": (v9/*: any*/),
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "TimeSeries",
                                "kind": "LinkedField",
                                "name": "execUnits",
                                "plural": false,
                                "selections": (v9/*: any*/),
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "TimeSeries",
                                "kind": "LinkedField",
                                "name": "buildDuration",
                                "plural": false,
                                "selections": (v9/*: any*/),
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "AccountResourcesStatus",
                            "kind": "LinkedField",
                            "name": "status",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ResourceStatus",
                                "kind": "LinkedField",
                                "name": "requestCount",
                                "plural": false,
                                "selections": (v10/*: any*/),
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ResourceStatus",
                                "kind": "LinkedField",
                                "name": "udfRequestCount",
                                "plural": false,
                                "selections": (v10/*: any*/),
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ResourceStatus",
                                "kind": "LinkedField",
                                "name": "execUnits",
                                "plural": false,
                                "selections": (v10/*: any*/),
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ResourceStatus",
                                "kind": "LinkedField",
                                "name": "buildDuration",
                                "plural": false,
                                "selections": (v10/*: any*/),
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "type": "AccountResourcesUsageDashboardSuccess",
                        "abstractKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "type": "Account",
                "abstractKey": "__isAccount"
              },
              (v8/*: any*/)
            ],
            "storageKey": null
          },
          (v8/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "56c144a8cc5fa7e43e6bc29ce92ad33a",
    "id": null,
    "metadata": {},
    "name": "personalUsagePageQuery",
    "operationKind": "query",
    "text": "query personalUsagePageQuery(\n  $cycle: LimitCycleChoice\n  $startDate: DateTime\n  $endDate: DateTime\n  $graphIds: [ID!]\n) {\n  viewer {\n    personalAccount {\n      ...usageControlsFragment_1L60Y3\n      ...usageDataFragment_1L60Y3\n      id\n    }\n    id\n  }\n}\n\nfragment usageControlsFragment_1L60Y3 on Account {\n  __isAccount: __typename\n  graphs {\n    nodes {\n      id\n      slug\n    }\n  }\n  resourcesUsageDashboard(filter: {limitCycleChoice: $cycle, startDate: $startDate, endDate: $endDate, graphIds: $graphIds}) {\n    __typename\n    ... on AccountResourcesUsageDashboardSuccess {\n      periodStart\n      periodEnd\n    }\n  }\n}\n\nfragment usageDataFragment_1L60Y3 on Account {\n  __isAccount: __typename\n  resourcesUsageDashboard(filter: {limitCycleChoice: $cycle, startDate: $startDate, endDate: $endDate, graphIds: $graphIds}) {\n    __typename\n    ... on AccountResourcesUsageDashboardSuccess {\n      usage {\n        requestCount {\n          unit\n          data {\n            value\n            datetime\n          }\n        }\n        udfRequestCount {\n          unit\n          data {\n            value\n            datetime\n          }\n        }\n        execUnits {\n          unit\n          data {\n            value\n            datetime\n          }\n        }\n        buildDuration {\n          unit\n          data {\n            value\n            datetime\n          }\n        }\n      }\n      status {\n        requestCount {\n          used\n          limit\n        }\n        udfRequestCount {\n          used\n          limit\n        }\n        execUnits {\n          used\n          limit\n        }\n        buildDuration {\n          used\n          limit\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5030c5ea2281085496a15e6044bb1ee9";

export default node;
