import node, {
  type graphApiKeysPageQuery,
} from '#gen/relay/graphApiKeysPageQuery.graphql'
import { lazyLoadedComponent } from '#lib/routing/lazy-loaded-component'
import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute(
  '/_dashboard/$account/$graph/branches/$branch/settings/graph/api-keys',
)({
  staticData: { title: '[graph] - API Keys' },
  loader({ context, params }) {
    return context.preload<graphApiKeysPageQuery>(node, params)
  },
  component: lazyLoadedComponent(
    () => import('#app/pages/settings/graph-api-keys-page'),
    'GraphApiKeysPage',
  ),
})
