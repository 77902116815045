import node, {
  type teamsTeamsPageQuery,
} from '#gen/relay/teamsTeamsPageQuery.graphql'
import { createFileRoute, lazyRouteComponent } from '@tanstack/react-router'

export const Route = createFileRoute(
  '/_dashboard/$account/_account/settings/teams/teams/',
)({
  staticData: { title: 'Teams' },
  loader({ context, params }) {
    return context.preload<teamsTeamsPageQuery>(node, params)
  },
  component: lazyRouteComponent(
    () => import('#app/pages/account/teams/teams-page/teams-teams-page'),
    'TeamsTeamsPage',
  ),
})
