/**
 * @generated SignedSource<<d9debd3581d13eebf506a09bcadcfe76>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type BranchEnvironment = "PREVIEW" | "PRODUCTION" | "%future added value";
export type pathfinderPageQuery$variables = {
  account: string;
  branch: string;
  graph: string;
};
export type pathfinderPageQuery$data = {
  readonly branch: {
    readonly domains: ReadonlyArray<string>;
    readonly environment: BranchEnvironment;
    readonly graph: {
      readonly apiKeys: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly environment: BranchEnvironment;
            readonly key: string;
          };
        }>;
      };
    };
    readonly schema: string | null | undefined;
  } | null | undefined;
};
export type pathfinderPageQuery = {
  response: pathfinderPageQuery$data;
  variables: pathfinderPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "account"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "branch"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "graph"
},
v3 = [
  {
    "kind": "Variable",
    "name": "accountSlug",
    "variableName": "account"
  },
  {
    "kind": "Variable",
    "name": "graphSlug",
    "variableName": "graph"
  },
  {
    "kind": "Variable",
    "name": "name",
    "variableName": "branch"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "domains",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "environment",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "schema",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "key",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "pathfinderPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Branch",
        "kind": "LinkedField",
        "name": "branch",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Graph",
            "kind": "LinkedField",
            "name": "graph",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "GraphApiKeyConnection",
                "kind": "LinkedField",
                "name": "apiKeys",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "GraphApiKeyEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "GraphApiKey",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          (v7/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "pathfinderPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Branch",
        "kind": "LinkedField",
        "name": "branch",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Graph",
            "kind": "LinkedField",
            "name": "graph",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "GraphApiKeyConnection",
                "kind": "LinkedField",
                "name": "apiKeys",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "GraphApiKeyEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "GraphApiKey",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          (v7/*: any*/),
                          (v8/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v8/*: any*/)
            ],
            "storageKey": null
          },
          (v8/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5db240b6da48128aaae6f7e177829ddb",
    "id": null,
    "metadata": {},
    "name": "pathfinderPageQuery",
    "operationKind": "query",
    "text": "query pathfinderPageQuery(\n  $account: String!\n  $graph: String!\n  $branch: String!\n) {\n  branch(accountSlug: $account, graphSlug: $graph, name: $branch) {\n    domains\n    environment\n    schema\n    graph {\n      apiKeys {\n        edges {\n          node {\n            environment\n            key\n            id\n          }\n        }\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "967a25fdbc9382cd959ce547bd1cdd26";

export default node;
