/**
 * @generated SignedSource<<46d4318ac0989124ffafcc48ed28bd0b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SchemaProposalStatus = "APPROVED" | "DRAFT" | "IMPLEMENTED" | "IN_REVIEW" | "REJECTED" | "%future added value";
export type proposalPageLayoutQuery$variables = {
  proposalId: string;
};
export type proposalPageLayoutQuery$data = {
  readonly schemaProposal: {
    readonly id: string;
    readonly status: SchemaProposalStatus;
    readonly title: string;
    readonly " $fragmentSpreads": FragmentRefs<"infoProposalFragment">;
  };
};
export type proposalPageLayoutQuery = {
  response: proposalPageLayoutQuery$data;
  variables: proposalPageLayoutQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "proposalId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "proposalId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "proposalPageLayoutQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "alias": null,
          "args": (v1/*: any*/),
          "concreteType": "SchemaProposal",
          "kind": "LinkedField",
          "name": "schemaProposal",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            (v3/*: any*/),
            (v4/*: any*/),
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "infoProposalFragment"
            }
          ],
          "storageKey": null
        },
        "action": "THROW",
        "path": "schemaProposal"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "proposalPageLayoutQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SchemaProposal",
        "kind": "LinkedField",
        "name": "schemaProposal",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "author",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "avatarUrl",
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdAt",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9542d7927f8415585e2744cb319a1fa6",
    "id": null,
    "metadata": {},
    "name": "proposalPageLayoutQuery",
    "operationKind": "query",
    "text": "query proposalPageLayoutQuery(\n  $proposalId: ID!\n) {\n  schemaProposal(id: $proposalId) {\n    id\n    title\n    status\n    ...infoProposalFragment\n  }\n}\n\nfragment infoProposalFragment on SchemaProposal {\n  author {\n    name\n    avatarUrl\n    id\n  }\n  createdAt\n  status\n}\n"
  }
};
})();

(node as any).hash = "20db2d88de854bbb569613e04537ee80";

export default node;
