import { Config } from '#app/config'
import node, {
  type billingPageQuery,
} from '#gen/relay/billingPageQuery.graphql'
import { lazyLoadedComponent } from '#lib/routing/lazy-loaded-component'
import { createFileRoute, notFound } from '@tanstack/react-router'

export const Route = createFileRoute(
  '/_dashboard/$account/_account/settings/billing',
)({
  staticData: { title: 'Billing' },
  loader({ context, params }) {
    if (Config.IS_ENTERPRISE_BUILD) throw notFound()

    return context.preload<billingPageQuery>(node, params)
  },
  component: lazyLoadedComponent(
    () => import('#app/pages/account/billing/billing-page'),
    'BillingPage',
  ),
})
