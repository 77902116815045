/**
 * @generated SignedSource<<50df1693d90cd757c036eee4e8d6534d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type dashboardLayoutQuery$variables = Record<PropertyKey, never>;
export type dashboardLayoutQuery$data = {
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"dashboardLayoutIdentifyUserFragment" | "dynamicPageTitle" | "providerFeatureFlagsFragment" | "selectsDataWrapperAccountsFragment">;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"accountPermissionsFragment" | "headerFragment">;
};
export type dashboardLayoutQuery = {
  response: dashboardLayoutQuery$data;
  variables: dashboardLayoutQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "plan",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "dashboardLayoutQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "headerFragment"
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "accountPermissionsFragment"
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "dashboardLayoutIdentifyUserFragment"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "selectsDataWrapperAccountsFragment"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "providerFeatureFlagsFragment"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "dynamicPageTitle"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "dashboardLayoutQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PersonalAccount",
            "kind": "LinkedField",
            "name": "personalAccount",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "OrganizationConnection",
            "kind": "LinkedField",
            "name": "organizations",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/),
                  (v0/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "avatarUrl",
            "storageKey": null
          },
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Member",
            "kind": "LinkedField",
            "name": "organizationMemberships",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "role",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "account",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  (v1/*: any*/),
                  (v0/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              (v1/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdAt",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "46d0dacf356fd3122239b7c48fb58acb",
    "id": null,
    "metadata": {},
    "name": "dashboardLayoutQuery",
    "operationKind": "query",
    "text": "query dashboardLayoutQuery {\n  ...headerFragment\n  ...accountPermissionsFragment\n  viewer {\n    ...dashboardLayoutIdentifyUserFragment\n    ...selectsDataWrapperAccountsFragment\n    ...providerFeatureFlagsFragment\n    ...dynamicPageTitle\n    id\n  }\n}\n\nfragment accountPermissionsFragment on Query {\n  viewer {\n    personalAccount {\n      slug\n      id\n    }\n    organizationMemberships {\n      role\n      account {\n        __typename\n        id\n        slug\n        plan\n      }\n      id\n    }\n    id\n  }\n}\n\nfragment accountSelectFragment on User {\n  name\n  personalAccount {\n    slug\n    id\n  }\n  organizations {\n    nodes {\n      slug\n      name\n      id\n    }\n  }\n}\n\nfragment dashboardLayoutIdentifyUserFragment on User {\n  id\n  name\n  email\n  createdAt\n  avatarUrl\n  personalAccount {\n    slug\n    id\n  }\n  organizations {\n    nodes {\n      id\n      slug\n      name\n      plan\n    }\n  }\n}\n\nfragment dynamicPageTitle on User {\n  organizations {\n    nodes {\n      slug\n      name\n      id\n    }\n  }\n}\n\nfragment headerFragment on Query {\n  viewer {\n    personalAccount {\n      slug\n      id\n    }\n    ...helpModalFragment\n    ...profileMenuFragment\n    id\n  }\n}\n\nfragment helpModalFragment on User {\n  organizations {\n    nodes {\n      id\n      name\n      slug\n    }\n  }\n}\n\nfragment profileMenuFragment on User {\n  name\n  email\n  avatarUrl\n}\n\nfragment providerFeatureFlagsFragment on User {\n  id\n  email\n  name\n  personalAccount {\n    id\n    slug\n  }\n  organizations {\n    nodes {\n      id\n      slug\n    }\n  }\n}\n\nfragment selectsDataWrapperAccountsFragment on User {\n  ...accountSelectFragment\n}\n"
  }
};
})();

(node as any).hash = "07698ed75f2f093c072c7e37031e843f";

export default node;
