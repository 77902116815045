import { Config } from '#app/config'
import node, {
  type newGraphPageQuery,
} from '#gen/relay/newGraphPageQuery.graphql'
import { lazyLoadedComponent } from '#lib/routing/lazy-loaded-component'
import { createFileRoute, notFound } from '@tanstack/react-router'
import { parse } from 'valibot'

import { SearchParamsSchema } from './_dashboard.new.index'

export const Route = createFileRoute('/_dashboard/new/$account/')({
  staticData: { title: 'Create managed graph - [account]' },
  validateSearch(search) {
    return parse(SearchParamsSchema, search)
  },
  async loader({ context, params }) {
    if (Config.IS_ENTERPRISE_BUILD) throw notFound()

    return context.preload<newGraphPageQuery>(node, {
      slug: params.account,
    })
  },
  component: lazyLoadedComponent(
    () => import('#app/pages/graph/new-graph/new-graph-page'),
    'NewGraphPage',
  ),
})
