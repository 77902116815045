/**
 * @generated SignedSource<<8ef32fc36b6b0b8b265ecacb45793a65>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type personalOverviewPageQuery$variables = {
  from: string;
  to: string;
};
export type personalOverviewPageQuery$data = {
  readonly viewer: {
    readonly personalAccount: {
      readonly graphs: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly slug: string;
            readonly " $fragmentSpreads": FragmentRefs<"graphCardFragment">;
          };
        }>;
      };
      readonly id: string;
      readonly slug: string;
      readonly " $fragmentSpreads": FragmentRefs<"newGraphCardFragment">;
    } | null | undefined;
  } | null | undefined;
};
export type personalOverviewPageQuery = {
  response: personalOverviewPageQuery$data;
  variables: personalOverviewPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "from"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "to"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "personalOverviewPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PersonalAccount",
            "kind": "LinkedField",
            "name": "personalAccount",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "newGraphCardFragment"
              },
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "GraphConnection",
                "kind": "LinkedField",
                "name": "graphs",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "GraphEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Graph",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "graphCardFragment"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "personalOverviewPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PersonalAccount",
            "kind": "LinkedField",
            "name": "personalAccount",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ResourceStatus",
                "kind": "LinkedField",
                "name": "graphsCountStatus",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "remaining",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "GraphConnection",
                "kind": "LinkedField",
                "name": "graphs",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "GraphEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Graph",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "graphMode",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "graphType",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "account",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "__typename",
                                "storageKey": null
                              },
                              (v2/*: any*/),
                              (v1/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Branch",
                            "kind": "LinkedField",
                            "name": "productionBranch",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "name",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Deployment",
                                "kind": "LinkedField",
                                "name": "latestDeployment",
                                "plural": false,
                                "selections": [
                                  (v1/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "GitCommit",
                                    "kind": "LinkedField",
                                    "name": "commit",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "author",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "authorAvatarUrl",
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "createdAt",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              (v1/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": [
                              {
                                "fields": [
                                  {
                                    "kind": "Variable",
                                    "name": "from",
                                    "variableName": "from"
                                  },
                                  {
                                    "kind": "Variable",
                                    "name": "to",
                                    "variableName": "to"
                                  }
                                ],
                                "kind": "ObjectValue",
                                "name": "filter"
                              }
                            ],
                            "concreteType": "RequestAnalytics",
                            "kind": "LinkedField",
                            "name": "requestAnalytics",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "RequestMetricsTimeSeries",
                                "kind": "LinkedField",
                                "name": "timeSeries",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "RequestMetricsTimeSeriesDataPoint",
                                    "kind": "LinkedField",
                                    "name": "data",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "dateTime",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "requestCount",
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          (v1/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e873868ba83bce393ab58a0a80bb57f1",
    "id": null,
    "metadata": {},
    "name": "personalOverviewPageQuery",
    "operationKind": "query",
    "text": "query personalOverviewPageQuery(\n  $from: DateTime!\n  $to: DateTime!\n) {\n  viewer {\n    personalAccount {\n      ...newGraphCardFragment\n      id\n      slug\n      graphs {\n        edges {\n          node {\n            slug\n            ...graphCardFragment\n            id\n          }\n        }\n      }\n    }\n    id\n  }\n}\n\nfragment graphCardAnalyticsFragment on Graph {\n  requestAnalytics(filter: {from: $from, to: $to}) {\n    timeSeries {\n      data {\n        dateTime\n        requestCount\n      }\n    }\n  }\n}\n\nfragment graphCardFragment on Graph {\n  slug\n  graphMode\n  graphType\n  account {\n    __typename\n    slug\n    id\n  }\n  productionBranch {\n    name\n    latestDeployment {\n      id\n      commit {\n        author\n        authorAvatarUrl\n      }\n      createdAt\n    }\n    id\n  }\n  ...graphCardAnalyticsFragment\n}\n\nfragment newGraphCardFragment on PersonalAccount {\n  graphsCountStatus {\n    remaining\n  }\n}\n"
  }
};
})();

(node as any).hash = "8353adcc5d9843d10dc8070785aac03f";

export default node;
